/** @format */

import React, { useEffect, useRef } from "react";
import Nav from "./../Componentes/Nav";
import "./index.css";
import { Button } from "antd";
import Home7 from "./Home7";
import { Link  } from "react-router-dom";

export default function Home() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      // Establecer el tiempo de inicio en segundos
      videoRef.current.currentTime = 2;
    }
  }, []);

  return (
    <div className="contenedor-home">
      <Nav />
      <div className="titulosubtituloboton">
        <div className="titulo-pagina-home">
          Inteligencia artificial  y Big Data predictiva
        </div>

        <div className="subtitulo-pagina">
        La <strong> herramienta líder </strong> para gestionar la <strong> comunicación
          social y política </strong>
        </div>
        <Link to={'/mexico'} className="link"><Button className="boton-mexico">Elecciones México 2024</Button></Link>

 
        <div className="video-home">
        <video
        ref={videoRef}
        src='https://qsocialnow.com/static/Imagenes/QSN_ALTA.mp4'
        controls
        // autoPlay
        muted
        loop
        type="video/mp4"
        style={{ width: '76%', borderRadius: '3rem', marginTop: '12%', marginLeft: '12%' }}
      />
        </div>
      

        <div className="titulo-gif">
          Integramos un conjunto de <br />
          recursos en una única solución
        </div>

        <div className="subtitulo-gif">
          <div>
          <strong>Nos nutrimos de millones de datos de redes sociales</strong>, medios, encuestas, información sociodemográfica y fuentes de datos
          <strong>  públicas para descubrir tendencias </strong>
              y generar los indicadores que garantizan la eficacia de las acciones de segmentación de audiencias, la comunicación y el marketing.
          </div>
      </div>


      <div  className="gif">
        <img src='https://qsocialnow.com/static/Imagenes/Placa-HERRAMIENTAS_3_1.gif' style={{width:'95%'}}/>
      </div>

      <div className="contenedor-home-cartas">
      <div className="contenedor-titulo">
        <div className="titulo-home2">Contamos con</div>
        <div className="subtitulo-home2">
          <stong>La capacidad de</stong>
        </div>
      </div>

      <div className="contenedor-cartas-home2">
        
        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Visualization.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
            Monitorear nuestras fuentes de datos en tiempo real
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Research%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Aplicar modelos analíticos avanzados para interpretar la información
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Consulting%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Explicar comportamientos sociales combinando variables racionales y emocionales
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Visualization%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Anticipar tendencias en escenarios prospectivos
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Machine%20Learning%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Generar recomendaciones basadas en el análisis de datos
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Structured%20Data%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Disponer de potentes recursos para la segmentación de las audiencias
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Engineering%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Identificar las demandas en cada caso
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Business%20Intelligence%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Implementar campañas de marketing basadas en segmentos y micro-segmentos
          </div>
        </div>

        <div className="carta">
          <img src='https://qsocialnow.com/static/Imagenes/Document%20Management%201.png' className="imagen-carta-home"/>
          <div className="carta-contenido">
          Presentar los resultados del análisis y las acciones de campañas en informes,
mapas y dahaboard interactivos
          </div>
        </div>

        
      </div>
    </div>

    <div className='contenedor-mapa-home'>
        
        <div className='carta-transparente'>
        <div className='titulo-mapamundi'>Más de 200 proyectos desarrollados en la región y el mundo</div>
          <img src='https://qsocialnow.com/static/Imagenes/Group%2081.png' className="mapamundi-imagen"/>
        </div>
      </div>

      <div className="contenedor-home4">

<div className="contenedor-texto-home4">
  <div className="titulo-home4">
    Analítica <br /> Avanzada
  </div>
  <div className="subtitulo-home4">
    Más de 150 categorías representadas en{" "}
    <strong> modelos analíticos </strong> basados en
    <strong> inteligencia artificial </strong> permiten al usuario
    profundizar en la comprensión de los escenarios y sus tendencias.
  </div>
</div>

<div className="contenedor-titulo-imagen-home4">
<div className="contenedor-texto-home4-botoom">
  <div className="titulo-home4">
    Trabajamos en <br /> un enfoque
  </div>
  <div className="subtitulo-home4-bootom">
    Que analiza la dinámica de la <strong> opinión pública y el estudio de las
    audiencias </strong> combinando la dimensión racional con la dimensión
    emocional, cada vez más influyente en las conductas de ciudadanos y
    consumidores.
  </div>
</div>

<img src='https://qsocialnow.com/static/Imagenes/Group%2054.png' className="foto-chico-home"/>

</div>

<div className='contenedor-cartas-home4'>
  <div className="cartas-home4">
  Tendencias Positivas <br/> y Negativas
  </div>

  <div className="cartas-home4">
  Preocupaciones e <br/> Intereses Ciudadanos
  </div>

  <div className="cartas-home4">
  Emociones <br/> y Sentimientos
  </div>

  <div className="cartas-home4">
  Atributos <br/> del Liderazgo
  </div>

  <div className="cartas-home4">
  Interpretación del <br/> Clima de Época
  </div>

  <div className="cartas-home4">
  Comportamientos <br/> Electorales
  </div>

  <div className="cartas-home4">
  Mapa Dinámico <br/> de Percepciones
  </div>

  <div className="cartas-home4">
  Género y Grupos <br/> Etarios
  </div>
  
  <div className="cartas-home4">
  Informes, Grafos, <br/> Mapas y Reportes
  </div>

</div>



<div className="contenedor-boton-hoja4">
<Button className="boton-vermas-bigdataPredictiva link"><Link to={'/analiticaAvanzada'} className="link" onClick={handleScrollToTop}>Ver Mas</Link></Button>
</div>




</div>


<div className="contenedor-home5">
      <div className="titulo-home5">Big Data predictiva</div>

      <div>

        <div className="subtitulo-home5">
        Basándonos en información de carácter público, nuestros algoritmos
        combinan una
        <br />
        multiplicidad de variables que, correlacionadas, permiten predecir con
        niveles crecientes de
        <br />
        <strong> probabilidad distintas tendencias </strong> que hacen al comportamiento social y
        político.
        </div>

        <div className="subtitulo-home5">
        <br />
        <strong> Los modelos predictivos </strong> desarrollados por QSocialNow <strong> combinan e integran
        más de 100
        <br />
        capas de datos incluyendo estadísticas sociodemográficas, económicas, de
        intereses y
        <br />
        consumos, electorales,</strong> entre muchas otras fuentes de información, con la
        analítica de la
        <br />
        opinión pública generada por nuestros modelos de inteligencia artificial
        basados en la
        <br />
        escucha social, encuestas digitales y estudios cualitativos.
        </div>

      </div>

   
      <div className="contenedor-boton-hoja4-bigdata">
    <Button className="boton-vermas-bigdataPredictiva link"><Link to={'/bigdataPredictiva'} className="link" onClick={handleScrollToTop}> Ver Mas</Link></Button>
      </div>
    
     
    </div>


    <div className="grafos-interactivos">
    <Button  className="boton-mexico-analisisAvanzado" ><Link to='https://qsocialnow.com/static/Imagenes/grafo_hashtags_menciones.html'target="_blank">Pantalla Completa</Link></Button>
      </div>

    <div className='contenedor-home6'>

<div className='titulo-home6'>Otras tecnologías y servicios</div>

<div>

<div className='contenedor-cartas-home6'>

    <Link to='/socialListening' className="link cartashome-servicios" onClick={handleScrollToTop} ><div className='cuadrado-imagen-home6'>
    Social Listening
    </div></Link>

    <Link to='/marketingDigital' className="link cartashome-servicios" onClick={handleScrollToTop}><div className='cuadrado-imagen-home6-1'>
    Marketing <br/> Digital
    </div></Link>

    <Link to='/programatic' className="link cartashome-servicios" onClick={handleScrollToTop}><div className='cuadrado-imagen-home6-2'>
    Programmatic<br/>Advertising
    </div></Link>

    <Link to='/comunicacionDirecta' className="link cartashome-servicios" onClick={handleScrollToTop}><div className='cuadrado-imagen-home6-3'>
    Comunicación<br/>Directa
    </div></Link>

    <Link to='/consultoria' className="link cartashome-servicios" onClick={handleScrollToTop}><div className='cuadrado-imagen-home6-4'>
    Consultoría
    </div></Link>

    <Link to='/opinionPublica' className="link cartashome-servicios" onClick={handleScrollToTop} ><div className='cuadrado-imagen-home6-5'>
    Opinión<br/>Pública
    </div></Link>

    <Link to='/capacitacion' className="link cartashome-servicios" onClick={handleScrollToTop} ><div className='cuadrado-imagen-home6-6'>
    Capacitación
    </div></Link>

    
</div>


<img src='https://qsocialnow.com/static/Imagenes/hand-2%201.png' className='imagen-manorobot' style={{width:'30%'}}/>
</div>


</div>

      <Home7/>
      </div>
    </div>
  );
}
