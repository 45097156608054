import { Button, Form, Input , message} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useRef } from 'react'
import './index.css'

export default function FormularioContacto() {
  const [messageApi, contextHolder] = message.useMessage();
  const emailCorporativo = 'no-reply@qsocialnow.com'

  const formRef = useRef(null);


  const success = (mensaje) => {
    messageApi.open({
      type: 'success',
      content: mensaje,
    });
  };

  const sendFeedback = (serviceID, templateId, variables) => {
    window.emailjs.send(
        serviceID,
        templateId,
        variables
    ).then(res => {
        console.log('Email successfully sent!')
    })
        .catch(err => console.error('There has been an error.  Here some thoughts on the error that occured:', err))
}

const onFinish = (data, r) => {
  success(`Gracias por mandarnos un mail ${data.name}, nos pondremos en contacto.`);
  const templateId = 'template_n60t7a2';
  const serviceID = 'service_jtmgt8d';
  
  const messageContent = `Nombre: ${data.name}\nTeléfono: ${data.telefono}\nEmail: ${data.email}\nMensaje: ${data.mensaje}`;
  sendFeedback(serviceID, templateId, {
    from_name: data.name,
    message_html: messageContent,
    reply_to: emailCorporativo,
  });
  
  r.target.reset();
  formRef.current.resetFields();
};


  return (
    <div className='contenedor-formulario'>
      <div>Contacto</div>
      <Form
    name="Contacto"
    onFinish={onFinish}
    ref={formRef}
  >
    {contextHolder}
      <Form.Item
      name='name'
      rules={[
        {
          required: true,
          message: 'Por favor escribí tu nombre'
        },
      ]}
    >
      <Input className='input-oscuro-footer' placeholder='Nombre y Apellido'/>
      </Form.Item>

      <Form.Item
      name='email'
      rules={[
        {
          type: 'email',
          required: true,
          message: 'Por favor escribí tu email'
        },
      ]}
    >
      <Input className='input-oscuro-footer' placeholder='Correo electronico'/>
      </Form.Item>

      <Form.Item
        name="telefono"
        rules={[{ required: true, message: 'Por favor escribí tu número de teléfono' }]}
      >
      <Input className='input-oscuro-footer' placeholder='Teléfono'/>
      </Form.Item>

      <Form.Item
        name="mensaje"
        rules={[{ required: true, message: 'Por favor escribí un mensaje' }]}
      >
      <TextArea className='input-oscuro-footer' placeholder='Mensaje'/>
      </Form.Item>
      <Button className='boton-enviar-formulario' htmlType="submit">Enviar</Button>
      </Form>
    </div>

  )
}
