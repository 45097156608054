/** @format */

import React, { useEffect, useState } from "react";
import "./index.css";
import { BsArrowRight } from "react-icons/bs";
import { Button, Input, message } from "antd";

export default function Home7() {
  const emailCorporativo = "no-reply@qsocialnow.com";
  const [email, setEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [errorMail, setErrorMail] = useState("");
  const [succesMail, setSuccesMail] = useState("");

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: succesMail,
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: errorMail,
    });
  };
  const sendFeedback = (serviceID, templateId, variables) => {
    window.emailjs
      .send(serviceID, templateId, variables)
      .then((res) => {
        console.log('Email successfully sent!', res);
        setSuccesMail("Gracias por mandarnos tu solicitud");
        success();
      })
      .catch((err) => {
        console.error('Error sending email:', err);
        setErrorMail("Ocurrió un error, intentar nuevamente");
        error();
      });
  };
  
  useEffect(() => {
    if (errorMail !== "") {
      error();
      setErrorMail("");
    }

    if (succesMail !== "") {
      success();
      setSuccesMail("");
    }
  }, [errorMail, succesMail]);

  const onFinish = () => {
    if (!isEmailValid(email)) {
      setErrorMail("Ingrese un correo electrónico válido");
      return;
    }

    const templateId = "template_r7rjcjw";
    const serviceID = "service_jtmgt8d";

    sendFeedback(serviceID, templateId, {
      from_name: email,
      message_html: email,
      reply_to: emailCorporativo,
    });

    setEmail("");
  };

  return (
    <div className="contenedor-home7">
      <div className="formulario-home7">
        <div className="titulo-subtitulo-home7">
          <div className="titulo-home7">Solicitar Demo</div>
          <div className="subtitulo-home7">
            Enviá tu mail y un asesor de QSN se contactará con usted{" "}
          </div>
        </div>
        <Input
          value={email}
          onChange={handleInputChange}
          placeholder={`Envianos tu Email y nos pondremos en contacto`}
          style={{
            height: "20%",
            width: "100%",
            marginTop: "10%",
            padding: "3%",
          }}
        />
        {contextHolder}
        <Button onClick={onFinish} style={{ marginTop: "12%", zIndex: 99999 }}>
          <BsArrowRight />
        </Button>
      </div>
    </div>
  );
}
